import {Component} from "react";
import SpeedChart from "./SpeedChart";
import {RealTimeSpeed} from "./RealTimeSpeed";
import './Layout.css';
import {SpeedData} from "./SpeedData";
import CaptureData from "./CaptureData";

class Layout extends Component {
    render() {
        return (
            <div id="widget-container">X
                <div id="widget1" className="widget">
                    <RealTimeSpeed />
                </div>
                <div id="widget2" className="widget">
                    <CaptureData />
                </div>
                <div id="widget3" className="widget">
                    <SpeedChart />
                </div>
                <div id="widget4" className="widget">
                    <SpeedData />
                </div>
            </div>
        )
    }
}


export default Layout;
