import React, {Component} from "react";
import './CaptureData.css';

class CaptureData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            captureTargetId: 'car-speed',
        };
    }

    emitter = require('tiny-emitter/instance');

    componentDidMount = () => {
        let currentComponent = this;

        this.emitter.on('capture-update', function (targetId) {
                console.log("New capture update received: " + targetId);
                currentComponent.setState({captureTargetId: targetId});

        });

    };

    render() {
        return (
            <>
                <h2>Capture</h2>
                <div id="capture-image"><img className={"capture-image"} src={"/captures/" + this.state.captureTargetId + ".jpg"} alt={this.state.captureTargetId} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/images/car-speed.png";
                }}/></div>
            </>
        )
    }
}

export default CaptureData;
