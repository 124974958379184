
import {Client} from "@stomp/stompjs";
const SpeedStream = () => {

    let emitter = require('tiny-emitter/instance');

    const DEV_URL = 'ws://joemac:8777/socket';
    const PROD_URL = 'wss://thespeedbox.com/socket';

    let lastActivity = Date.now();
    let idleTimer = 0;

    let onConnected = () => {
        console.log("Speed socket connected. Subscribing to speed data = Booyah.");
        idleTimer = setInterval(markIdle, 1000);
        emitter.emit('speed-event', {eventType: "CONNECTED", speed: '0', direction: "right", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
        //setState( {speed: '0', direction: "right", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
        client.subscribe('/topic/subscriber', function (msg) {
            // console.log("Speed socket message received.");
            lastActivity = Date.now();
            //console.log(msg);
            if (msg.body) {
                //console.log(msg.body);
                let jsonBody = JSON.parse(msg.body);
                if (jsonBody.detectionFlag !== 'NONE') {
                    let formattedSpeed = jsonBody.speed;
                    let direction = 'right';
                    //if speed is negative then multiply by -1 to get positive value
                    if (formattedSpeed < 0) {
                        formattedSpeed = formattedSpeed * -1;
                        direction = 'left'
                    }
                    //convert to mph
                    formattedSpeed = Math.round(formattedSpeed/1.609344);

                    emitter.emit('speed-event', { eventType: jsonBody.detectionFlag, speed: formattedSpeed, direction: direction, speedData: jsonBody });

                    if(jsonBody.detectionFlag === 'END') {
                        emitter.emit('speed-data-changed', jsonBody);
                        emitter.emit('capture-update', jsonBody.targetId);
                    }
                    //currentComponent.setState({ speed: formattedSpeed, direction: direction, speedData: jsonBody }

                }
            }
        });
    }

    let markIdle = () =>{
        const now = Date.now();
        const timeSinceLastActivity = now - lastActivity;
        // console.log("Time since last activity: " + timeSinceLastActivity)
        // Check if 10 seconds have passed since last activity
        if (timeSinceLastActivity >= 12000) {
            console.log("Idle for 12 seconds. Disconnecting speed socket.");
            emitter.emit('speed-event', {eventType: "BOX-DISCONNECTED", speed: '0', direction: "right", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
        }
    }

    let onDisconnected = () => {
        // currentComponent.setState( {messages: 'X', direction: "none", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
        clearInterval(this.idleTimer);
         console.log("Speed socket disconnected :(");
    }

    let socketUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? DEV_URL : PROD_URL;

    const client = new Client({
        brokerURL: socketUrl,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        onConnect: onConnected,
        onDisconnect: onDisconnected,
        debug: function (str) {
            if(window.debugEnabled) {
                console.log(str);
            }
        },
        onStompError: function (frame) {
            console.log('Broker reported error: ' + frame.headers['message']);
            //currentComponent.setState( {messages: 'X', direction: "none", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
            console.log('Additional details: ' + frame.body);
            clearInterval(idleTimer);
        },
        onWebSocketError: function (frame) {
            //currentComponent.setState( {messages: 'X', direction: "none", speedData: {speed: '0', distance: '0', angle: '0', magnitude: '0'}});
            console.log('Socket reported error: ' + frame);
            clearInterval(idleTimer);
        },
    });

    if(!client.connected) {
        client.activate();
    }

}

export default SpeedStream;
