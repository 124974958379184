import React, {Component} from "react";
import './SpeedData.css';

export class SpeedData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connectionStatus: false,
            speed: 'X',
            direction: 'none',
            speedData: {
                speed: '0',
                distance: '0',
                angle: '0',
                magnitude: '0'
            },
        };
    }
    //add SpeedData.css

    emitter = require('tiny-emitter/instance');

    componentDidMount() {
        let currentComponent = this;

        this.emitter.on('speed-event', function (data) {
            //console.log("New speed event received: " + data.speed);
            if(data.eventType !== 'END') {
                if(data.eventType === 'BOX-DISCONNECTED') {
                    currentComponent.setState({
                        connectionStatus: false,
                        speed: 'X',
                        direction: 'none',
                        speedData: {
                            speed: '0',
                            distance: '0',
                            angle: '0',
                            magnitude: '0'
                        }
                    });
                }
                currentComponent.setState(data);
            }
        });

    };

    render() {
        return (
                <div id="speedbox-info">
                    <h2>Current Latest Raw:</h2>
                    <div id="speedbox-speed">Speed: {this.state.speedData.speed}</div>
                    <div id="speedbox-distance">Distance: {this.state.speedData.distance}</div>
                    <div id="speedbox-angle">Angle: {this.state.speedData.angle}</div>
                    <div id="speedbox-magnitude">Magnitude: {this.state.speedData.magnitude}</div>
                </div>
        )
    }
}
