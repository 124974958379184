import React, {Component} from 'react';

import './RealTimeSpeed.css';

export class RealTimeSpeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connectionStatus: false,
            speed: 'X',
            direction: 'none',
            speedData: {
                speed: '0',
                distance: '0',
                angle: '0',
                magnitude: '0'
            },
        };
    };

    emitter = require('tiny-emitter/instance');

    componentDidMount = () => {
        let currentComponent = this;

        this.emitter.on('speed-event', function (data) {
            //console.log("New speed event received: " + data.speed);
            if(data.eventType !== 'END') {
                if(data.eventType === 'BOX-DISCONNECTED') {
                    currentComponent.setState({
                        connectionStatus: false,
                        speed: 'X',
                        direction: 'none',
                        speedData: {
                            speed: '0',
                            distance: '0',
                            angle: '0',
                            magnitude: '0'
                        }
                    });
                }
                currentComponent.setState(data);
            }
        });

    };

    render() {
        return (
            <>
            <h2>Current/Latest MPH</h2>
                <div id="speedbox-value" className={this.state.direction} >{this.state.speed}</div>
            </>
        );
    }

}

