
import React, {Component} from "react";
import {
    CartesianGrid, DefaultTooltipContent,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

import NumberPicker from "react-widgets/NumberPicker";

export class SpeedChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };

        this.hour = localStorage.getItem('hourRange');

        if (this.hour == null) {
            this.hour = 1;
        }
    };

    emitter = require('tiny-emitter/instance');

    initialHour = 1;
    hour = 1;
    loadData = () => {

        fetch(`/api/speed-data/recent-speed-events/${this.hour}`)
            .then((response) => response.json())
            .then((data) => {
                //console.log(data);
                this.setState( {data: data});
            });
    }

    changeHourRange (hour){
        localStorage.setItem('hourRange', hour);
        this.hour = hour;
        this.loadData();
    }

    componentDidMount() {
        let currentComponent = this;
        this.hour = localStorage.getItem('hourRange');
        if (this.hour == null) {
            this.hour = 1;
        }

        this.loadData();

        this.emitter.on('speed-data-changed', function (data) {
                console.log("Speed data changed");
                currentComponent.loadData();
        })
        }

    updateCapture = (targetId) => {
        this.emitter.emit('capture-update', targetId);
    }

    CustomTooltip = props => {
        // payload[0] doesn't exist when tooltip isn't visible
        //
        //     // mutating props directly is against react's conventions
        //     // so we create a new payload with the name and value fields set to what we want
        //     // const newPayload = [
        //     //     {
        //     //         name: 'Name',
        //     //         // all your data which created the tooltip is located in the .payload property
        //     //         value: props.payload[0].payload.name,
        //     //         // you can also add "unit" here if you need it
        //     //     },
        //     //     ...props.payload,
        //     // ];
        //     const newPayload = props.payload;
        //
        //     // we render the default, but with our overridden payload
        //     return <DefaultTooltipContent {...props} payload={newPayload} />;
        // }

        if (props.payload[0] != null) {
           // console.log("payload: " + props.payload[0].payload.targetId);
            this.updateCapture(props.payload[0].payload.targetId);
        }
        // we just render the default
        return <DefaultTooltipContent {...props} />;
    }

    render() {

        return (
            <div id="speed-chart">
                <h3> Most Recent Data in Hours <NumberPicker defaultValue={1} onChange={hour => this.changeHourRange(hour)} /> </h3>
            <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}>
                    <CartesianGrid />
                    <XAxis type="category" dataKey="formattedTime" name="Time"/>
                    <YAxis type="number" dataKey="speed" name="Speed" unit=" mph" />
                    <Tooltip content={this.CustomTooltip} cursor={{ strokeDasharray: '3 3' }} on />
                    <Scatter name="Greenbrier Speed Data" data={this.state.data} fill="#000000" >

                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
                </div>
        )
    }

}

export default SpeedChart;
