import Layout from "./component/Layout";
import React from "react";
import speedStream from "./services/speedStream";

const App = () => {
    //use styles from App.css
    require('./App.css');

    speedStream();

    return (

            <div className="App">
                <div className="App-header">
                    <div id="speedbox-title">The Speed Box Project</div>
                </div>
                <Layout />
            </div>
    );
}
export default App;
